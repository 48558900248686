import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import shareResource from './_helpers/shareResource';
import {
  apiPost, generateFormData, apiStore,
} from '../../modules/apiHelper';
import { now, urlFriendly } from '../../modules/dataHelper';

const PARAM_IDS = {
  company: {
    all: 1,
    specific: 2,
    mentors: 3,
    mentees: 4,
    // companies: 5,
  },
  // myCompany: {
  //   all: 1,
  //   mentors: 3,
  //   mentees: 4,
  // },
  resourceTypeFile: 0,
  resourceTypeText: 1,
};

export default {
  shareResource(context, [resource, to = 'all', CompanyId = undefined, userId = undefined]) {
    const senderType = context.rootState.User.type;
    const isOtherAdmin = context.rootState.User.accountTypes.staff === senderType;
    const companyId = isOtherAdmin ? context.rootGetters.userCompanyId : CompanyId;
    const senderid = context.rootState.User.id;
    const resourceType = typeof resource === 'object'
      ? PARAM_IDS.resourceTypeFile
      : PARAM_IDS.resourceTypeText;
    //

    const formData = generateFormData({
      senderid,
      name: resource.name,
      resource_type: resourceType,
      comp_stat: PARAM_IDS.company[to],
      companyid: companyId,
    });

    formData.append('file1', resource, urlFriendly(resource.name));

    if (userId) {
      formData.set('userid', userId);
    } else {
      // formData.set('medium', PARAM_IDS.company[to]);
    }

    return shareResource(formData);
    // return shareResource(senderId, userId, paramTo, resource, resourceType);
  },

  async getResources(context) {
    const { companyid, id: userId } = context.rootState.User;
    if (!companyid) {
      return false;
    }

    const isOtherAdmin = context.rootState.User.type === context.rootState.User.accountTypes.staff;
    // const requestName = isOtherAdmin ? 'company_resource_log' : 'resource_list';
    // const request = isOtherAdmin
    //   ? apiPost(requestName, generateFormData({ companyid }), 10)
    //   : apiGet(requestName, null, null, true);
    //
    const requestName = 'company_resource_log';
    const request = apiPost(requestName, generateFormData({ companyid }), 10);


    // xhr then update store
    const response = request
      .catch((err) => {
        console.warn('getResources::resources err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) {
          return false;
        }

        const resources = _reverse(_sortBy((res.data[requestName] || []), 'dateOfPost'));
        if (resources.length) {
          apiStore.setItem(`resources/${companyid}`, [now(), resources]);
        }

        const data = [];

        const prom = [];
        resources.forEach((resource, index) => {
          if (Number(companyid) !== Number(resource.companyid) && isOtherAdmin) return;

          data[index] = resource;

          // * get foreign company data
          if (Number(companyid) !== Number(resource.companyid)) {
            const prc = context.dispatch('getCompany', [resource.companyid]).then((company) => {
              data[index] = (company ? { ...(data[index] || resource), company } : resource);
            });

            prom.push(prc);
          }

          // * get other admin's user data
          if (Number(resource.senderid) !== Number(userId)) {
            const pru = context.dispatch('getUserData', resource.senderid).then((user) => {
              data[index] = (user ? { ...(data[index] || resource), sender: user } : resource);
            });

            prom.push(pru);
          }
        });
        await Promise.all(prom);

        return data;
      });

    // if (!isStaleData(resources[0]) && resources[1].length) return resources[1];

    // wait for the xhr
    const result = await response;
    return result;
  },
};
